import { add, reduce, path, subtract, pipe, values, isNil } from 'ramda';
import sortByYear from '../../../utils/sortByYear';
import calculateSignalValue from '../../../utils/calculateSignalValue';

export const formatCalculatedResistance = pipe(
  path(['indicators', 'reservePosition', '_2020', 'calculatedResistance']),
  n => n.toFixed(2)
);

export const getExcessiveReserve = path([
  'indicators',
  'signalingValue',
  '_2020',
  'excessiveReserve',
]);

export const getExcessiveReservePercentage = path([
  'indicators',
  'signalingValue',
  '_2020',
  'excessiveReservePercent',
]);

export const getSignalValue = path([
  'indicators',
  'signalingValue',
  '_2020',
  'signalValue',
]);

export const getNetGain = path([
  'indicators',
  'signalingValue',
  '_2020',
  'netGain',
]);

export const getPublicEquity = path([
  'indicators',
  'signalingValue',
  '_2020',
  'publicEquity',
]);

export const getMaterialFixedAssets = path([
  'indicators',
  'reservePosition',
  '_2020',
  'materialFixedAssets',
]);

export const getEquity = path([
  'indicators',
  'reservePosition',
  '_2020',
  'equity',
]);

export const getGovermentGrant = path([
  'indicators',
  'reservePosition',
  '_2020',
  'governmentGrants',
]);

export const formatSupportBudget = partnership => {
  const {
    indicators: { supportBudget },
  } = partnership;

  const data = reduce(
    (acc, year) => {
      acc[year] = {
        label: year.substring(1),
        totalBenefits: supportBudget[year]
          ? supportBudget[year]['totalBenefits']
          : null,
        totalCharges: supportBudget[year]
          ? supportBudget[year]['totalCharges']
          : null,
      };
      return acc;
    },
    {},
    sortByYear(Object.keys(supportBudget))
  );
  return values(data);
};

export const formatReservePosition = partnership => {
  const {
    indicators: { reservePosition },
  } = partnership;
  const data = reduce(
    (acc, year) => {
      acc[year] = [
        {
          label: partnership.shortName,
          values: [
            {
              label: 'Weerstandsvermogen',
              value: reservePosition[`_${year}`]
                ? reservePosition[`_${year}`].resistance
                : '-',
            },
          ],
        },
      ];

      return acc;
    },
    {},
    sortByYear(Object.keys(reservePosition).map(x => x.replace('_', '')))
  );

  return data;
};

export const formatEquity = (partnership, predictions) => {
  const {
    indicators: { equityIndicator },
  } = partnership;

  const data = reduce(
    (acc, year) => {
      const formattedYear = `_${year}`;
      const currentPrediction = predictions.find(prediction => {
        return prediction.year === year;
      });

      const setValue = (indicator, year, field) => {
        return !isNil(indicator[year])
          ? Math.round(indicator[year][field])
          : null;
      };

      const calculateNullable = (a = 0, b = 0) => {
        const result = Math.round(subtract(a, b));
        return result === 0 ? null : result;
      };

      acc.push({
        label: year,
        Eigenvermogen: setValue(equityIndicator, formattedYear, 'publicEquity'),
        Signaleringswaarde: setValue(
          equityIndicator,
          formattedYear,
          'signalValue'
        ),
        PrognoseEigenvermogen: currentPrediction
          ? currentPrediction.totalEquity
          : null,
        PrognoseSignaleringswaarde: currentPrediction
          ? calculateSignalValue(currentPrediction.totalIncome)
          : null,
        Afwijking: calculateNullable(
          setValue(equityIndicator, formattedYear, 'publicEquity'),
          setValue(equityIndicator, formattedYear, 'signalValue')
        ),
        PrognoseAfwijking: calculateNullable(
          currentPrediction && currentPrediction.totalEquity,
          currentPrediction &&
            calculateSignalValue(currentPrediction.totalIncome)
        ),
      });
      return acc;
    },
    [],
    ['2017', '2018', '2019', '2020', '2021', '2022', '2023']
  );

  const filteredData = data.filter(
    year =>
      !(
        isNil(year.PrognoseEigenvermogen) &&
        isNil(year.PrognoseSignaleringswaarde) &&
        isNil(year.Eigenvermogen) &&
        isNil(year.Signaleringswaarde)
      )
  );

  return filteredData;
};

export const formatPrognosisData = prognosisData => {
  let year,
    prognosis = [],
    totalBenefits = 0,
    totalCharges = 0;

  prognosisData.map(yearlyPrognosis => {
    for (const field in yearlyPrognosis) {
      if (field === 'year') {
        year = yearlyPrognosis[field];
      }
      if (field.includes('Charges')) {
        totalCharges = add(totalCharges, yearlyPrognosis[field]);
      }
      if (field.includes('Benefits')) {
        totalBenefits = add(totalBenefits, yearlyPrognosis[field]);
      }
    }
    prognosis.push({
      year,
      totalBenefits,
      totalIncome: Number(yearlyPrognosis['prognosisBenefitsGrossIncome']),
      totalEquity: Number(yearlyPrognosis['prognosisBenefitsEquity']),
      totalCharges,
    });
  });

  return prognosis;
};
